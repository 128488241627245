import Keycloak from 'keycloak-js';
import {onMounted, type Ref, ref, watch} from 'vue';
import * as Sentry from '@sentry/core';
import {useRuntimeConfig} from '#app';
import {useAuthStore} from '~/store/global/auth';
import {useUserDetailsStore} from '~/store/global/userdetails';

export enum KeycloakState {
	// eslint-disable-next-line no-unused-vars
	Loading = 'loading',
	// eslint-disable-next-line no-unused-vars
	Authenticated = 'authenticated',
	// eslint-disable-next-line no-unused-vars
	Unauthenticated = 'unauthenticated',
	// eslint-disable-next-line no-unused-vars
	Failed = 'failed',
}

const CLIENT_ID = 'brancherie';
const REALM_NAME = CLIENT_ID;

const authenticateKeycloak = async (state: Ref<KeycloakState>, keycloak: Keycloak) => {
	state.value = KeycloakState.Loading;

	const authStore = useAuthStore();
	const userDetailsStore = useUserDetailsStore();
	const runtimeConfig = useRuntimeConfig();

	const authenticated = await keycloak.init({
		flow: 'standard',
		onLoad: 'check-sso',
		silentCheckSsoRedirectUri: runtimeConfig.public.app.url + '/sso-check.html'
	});

	if (authenticated) {
		if (keycloak.token) {
			state.value = KeycloakState.Authenticated;
			authStore.setAuthenticated(keycloak.token);
			userDetailsStore.fetchUserDetails();
		} else {
			state.value = KeycloakState.Unauthenticated;
			authStore.setUnauthenticated();
			userDetailsStore.clearUserDetails();
		}
		return;
	}

	state.value = KeycloakState.Unauthenticated;
	authStore.setUnauthenticated();
	userDetailsStore.clearUserDetails();
	authStore.setLoginUrl(keycloak.createLoginUrl({redirectUri: runtimeConfig.public.app.url + '/companies?loginflow'}));
};

export const initKeycloak = () => {

	const authStore = useAuthStore();

	const state = ref<KeycloakState>(KeycloakState.Loading);

	const runtimeConfig = useRuntimeConfig();

	onMounted(() => {
		if (process.server) {
			state.value = KeycloakState.Unauthenticated;
			return;
		}

		try {
			const keycloak = new Keycloak({
				clientId: CLIENT_ID,
				realm: REALM_NAME,
				url: runtimeConfig.public.keycloak.url
			});

			keycloak.onTokenExpired = () => {
				keycloak.updateToken(1)
					.then(success => {
						if (!success) {
							state.value = KeycloakState.Unauthenticated;
							authStore.setUnauthenticated();
						}
					})
					.catch((e: boolean | Error) => {
						Sentry.captureException(e);
					});
			};

			keycloak.onAuthRefreshSuccess = () => {
				if (!keycloak.token) {
					state.value = KeycloakState.Unauthenticated;
					authStore.setUnauthenticated();
				} else {
					authStore.setAuthenticated(keycloak.token);
				}
			};

			watch(() => authStore.loggedOut, async (loggedOut) => {
				if (loggedOut) {
					await keycloak.logout({});
				}
			});

			authenticateKeycloak(state, keycloak)
				.catch(e => {
					state.value = KeycloakState.Failed;
					Sentry.captureException(e);
				});
		} catch (e) {
			state.value = KeycloakState.Failed;
			console.error(e);
			Sentry.captureException(e);
		}
	});

	return {state, isAuthenticated: () => state.value === KeycloakState.Authenticated};
};
